import { LinkGridModule } from '@spotify-internal/frodor-modules';
import { GridItem, isCustomGridContent } from './LinkGridFields';
import { getTemplateSocialCardAsset } from '../../Template/socialCard';
import { TemplateType } from '../../Template';
import { fixHref } from '../../utils';

export function getLinkForSearchHit(doc: any): string {
  const ctype = doc.contentType;
  if (ctype === 'blogPost') {
    return `/blog/${doc.urlSlug}`;
  }
  if (ctype === 'video') {
    return `/videos/all-videos/${doc.urlSlug}`;
  }
  if (ctype === 'videoPage') {
    return `/video/${doc.urlSlug}`;
  }
  if (ctype === 'faq') {
    return `/help/article/${doc.urlSlug}`;
  }
  if (ctype === 'landingPage') {
    return `/${doc.urlSlug}`;
  }
  if (ctype === 'articlePage') {
    return `/blog/${doc.urlSlug}`;
  }
  if (ctype === 'event') {
    return fixHref(doc.url) || '/';
  }
  if (ctype === 'customGridContent') {
    return fixHref(doc.url) || '/';
  }
  return '/';
}

/**
 * The shape of the items field in the LinkGridFields
 */
type ContentItem = Parameters<typeof LinkGridModule>[0]['items'][0];

/**
 * Transform a GridItem into a ContentItem, which is the shape that the FrodorLinkGridModule expects
 */
export const toContentItem = (item: GridItem): ContentItem => {
  const shared = {
    title: item.title,
    contentType: item.contentType,
    description: item.description,
    url: getLinkForSearchHit(item),
  };
  if (isCustomGridContent(item)) {
    return {
      ...shared,
      image: item.image,
    };
  }
  return {
    ...shared,
    // This is tech debt.
    // We should eventually unwind the logic in here to work with cf-contentful-types or TemplateType
    image: getTemplateSocialCardAsset(item as unknown as TemplateType)!,
  };
};
