import { useState, useEffect, useRef, MutableRefObject } from 'react';

// Define the return type of the hook
type UseVisibilityReturn = [boolean, MutableRefObject<HTMLDivElement | null>];

// Define the hook
export function useVisibility(threshold?: number): UseVisibilityReturn {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const currentRef = ref.current;

  useEffect(() => {
    const observerOptions: IntersectionObserverInit = {
      root: null, // Use the viewport as the root
      rootMargin: `0px`, // Apply the offset
      threshold: threshold || 0.5, // 60% of the element must be visible
    };

    const observerCallback: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        setIsVisible(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [currentRef, threshold]);

  return [isVisible, ref];
}
