// ignore-string-externalization

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { TEMPLATE_THEMES, MODULE_THEMES } from '../Theme';
import { Stack, Box } from '../ui';
import { Module } from '../Module';
import { TemplateType } from '.';
import { useDynamicNavBarColor } from './useDynamicNavBarColor';
import { ModuleType, ModuleCarouselType } from '../Module/types';
import { GraphicTransitionProvider } from '@spotify-internal/frodor-modules';

export type LandingPageTemplateType = TemplateType & {
  heroModule?: ModuleType;
  modules?: ModuleType[];
};

const carouselSlideIfPresent = (mod?: ModuleType) =>
  mod && 'carouselSlides' in mod
    ? (mod as ModuleCarouselType).carouselSlides?.[0]
    : mod;

export const LandingPageTemplate: React.FC<
  LandingPageTemplateType
> = template => {
  const { theme = 'Dark', heroModule, modules = [] } = template;
  const [activeSlide, setActiveSlide] = React.useState<
    ModuleType | undefined
  >();

  const tplTheme = TEMPLATE_THEMES[theme];

  const modTheme = (mod: ModuleType) => ({
    ...tplTheme,
    ...(MODULE_THEMES[mod.themeName || ''] || {}),
  });

  const allModules: any = [heroModule, ...(modules || [])].filter(m =>
    Boolean(m?.contentType),
  );

  const isFullBleed = (mod: ModuleType) =>
    [
      'accordionModule',
      'checklistModule',
      'editorialGridModule',
      'graphicTransitionModule',
      'faqLinksModule',
      'groupTestimonial',
      'headerModule',
      'heroBodyCarousel',
      'heroBodyModule',
      'heroHeaderCarousel',
      'heroNarrativeCarousel',
      'heroNarrativeQuoteSlide',
      'heroNarrativeTextSlide',
      'linkGrid',
      'featuredContent2Up',
      'featuredContent3up',
      'featuredContentModule',
      'fullWidthVideoModule',
      'fullWidthCarouselModule',
      'jumpLinkHeroModule',
      'miniCarouselNarrative',
      'recirculationModule',
      'relatedLinksModule',
      'singleFeatureModule',
      'singleTestimonial',
      'videoModule',
      'videoModuleLongBody',
      'pageBreakerModule',
    ].includes(mod.contentType);

  useDynamicNavBarColor(activeSlide || carouselSlideIfPresent(allModules?.[0]));

  const renderModule = React.useCallback(
    (mod: ModuleType, idx: number) => {
      const isHeader = mod.id === heroModule?.id;
      const isCarouselModule = 'carouselSlides' in mod;
      const modProps = {
        isHeader,
        // for carousel headers, track slide change so we can grab current color for nav bar
        onShowSlide: isHeader && isCarouselModule ? setActiveSlide : undefined,
        modulePageIndex: idx,
        ...mod,
      } as any;

      return <Module {...modProps} />;
    },
    [heroModule?.id],
  );

  return (
    <Stack data-testid={`landing-${template.urlSlug || 'unknown'}`}>
      <GraphicTransitionProvider allModules={allModules!}>
        {allModules.map((mod: ModuleType, idx: number) => (
          <ThemeProvider key={`${mod.id}-${idx}`} theme={modTheme(mod)}>
            <Box noPadding={isFullBleed(mod)} transparent={idx === 0}>
              {renderModule(mod, idx)}
            </Box>
          </ThemeProvider>
        ))}
      </GraphicTransitionProvider>
    </Stack>
  );
};
