import { Entry } from 'contentful';
import { TypeArticlePageSkeleton } from '@spotify-internal/s4x-masthead/esm/src/lib/generated-types';
import {
  TypeCustomGridContentSkeleton,
  TypeLandingPageSkeleton,
  TypeVideoPageSkeleton,
} from '../../../lib/generated-types';
import { ContentfulAsset } from '../../Contentful';

/**
 * contentType has been moved by src/features/Contentful/index
 */
type ContentfulArticlePage = Entry<
  TypeArticlePageSkeleton,
  'WITHOUT_LINK_RESOLUTION'
>['fields'] & {
  contentType: 'articlePage';
};

/**
 * contentType has been moved by src/features/Contentful/index
 */
type ContentfulLandingPage = Entry<
  TypeLandingPageSkeleton,
  'WITHOUT_LINK_RESOLUTION'
>['fields'] & {
  contentType: 'landingPage';
};

/**
 * contentType has been moved by src/features/Contentful/index
 */
type ContentfulVideoPage = Entry<
  TypeVideoPageSkeleton,
  'WITHOUT_LINK_RESOLUTION'
>['fields'] & {
  contentType: 'videoPage';
};

/**
 * contentType has been moved by src/features/Contentful/index
 */
type ContentfulCustomGridContent = Entry<
  TypeCustomGridContentSkeleton,
  'WITHOUT_LINK_RESOLUTION'
>['fields'] & {
  contentType: 'customGridContent';
};

/**
 * This has been flattened by src/features/Contentful/index
 * Any nested types that are used need to be manually added here
 */
type TransformedCustomGridContent = Omit<
  ContentfulCustomGridContent,
  'image'
> & {
  image: ContentfulAsset;
};

/**
 * This type guard is used to determine if the GridItem is a TransformedCustomGridContent
 */
export function isCustomGridContent(
  item: GridItem,
): item is TransformedCustomGridContent {
  return (
    (item as TransformedCustomGridContent).contentType === 'customGridContent'
  );
}

/**
 * The supported types for the LinkGridModule in Contentful
 * This is a union of all the types that can be used in the griditems field.
 *
 * As noted in the docs for the LinkGridFields, this data has been altered by src/features/Contentful/index
 * We are using the Contentful types as much as possible, and are trying to move away from the custom types
 * But, its out of scope for this PR to fix the types in the Contentful module
 */
export type GridItem =
  | ContentfulArticlePage
  | ContentfulLandingPage
  | ContentfulVideoPage
  | TransformedCustomGridContent;

/**
 * We tried to use cf-contentful-types to generate the types for the LinkGridFields
 * But, since the src/features/Contentful/index messes with the shape, its difficult or impossible to get the fields to map up
 * This is a huge disadvantage for a component like this that complex nested types :(
 */
export type LinkGridFields = {
  heading: string;
  subhead: string;
  griditems: {
    items: GridItem[];
  };
};
