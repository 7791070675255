// ignore-string-externalization

import React from 'react';
import { createWidget } from '@typeform/embed';
import styled from 'styled-components';
import { TemplateType } from '.';
import { Wait } from '../Layout';
import { cssSpacing } from '@spotify-internal/encore-web';
import { useCurrentUser } from '../../utils/CurrentUserProvider';

export type TypeformTemplateType = TemplateType & {
  formId?: string;
  requiredFeatureFlag?: string;
  forceLogin?: boolean;
};

const TypeformContainer = styled.div`
  width: 100%;
  height: 100vh;

  .typeform-widget {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      border: none;
      overflow: hidden;
      border-radius: ${cssSpacing('tighter-2')};
    }
  }
`;

export const TypeformTemplate: React.FC<TypeformTemplateType> = ({
  formId,
  forceLogin = true,
}) => {
  const user = useCurrentUser();
  const formRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if ((!forceLogin || user) && formId) {
      createWidget(formId, {
        container: formRef.current!,
        hideHeaders: true,
        hideFooter: true,
        hidden: {
          partner_id: user?.partnerIds?.google || 'unknown',
        },
      });
    }
  }, [user, formId, formRef]);

  return (
    <TypeformContainer ref={formRef}>
      {forceLogin && !user && <Wait cover />}
    </TypeformContainer>
  );
};
