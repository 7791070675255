// ignore-string-externalization

import React from 'react';
import styled from 'styled-components';
import { hexToRgba } from '../../../../../features/src/color-conversion';
import {
  screenSmMax,
  Text,
  cssSpacing,
  IconArrowRight,
  ButtonTertiary,
} from '@spotify-internal/encore-web';
import {
  DELAYS,
  fadeInAnimation,
  withAnimationContext,
} from '@spotify-internal/frodor-modules';
import { formatText, fixHref } from '../../../utils';
import { RelatedLinkType } from './RelatedLinkType';
import { ModuleType } from '../../types';
import { InteractiveElement } from '../../../../utils/InteractiveElement';

const RelatedLinkBox = styled.div``;

const Headline = withAnimationContext(styled(Text).attrs({
  forwardedAs: 'h1',
  variant: 'titleSmall',
})`
  ${fadeInAnimation(DELAYS.FIRST)};
  padding-bottom: 0px;
`);

const Subtext = withAnimationContext(styled(Text).attrs({
  forwardedAs: 'p',
  variant: 'bodyMedium',
})`
  ${fadeInAnimation(DELAYS.SECOND)};

  @media (max-width: ${screenSmMax}) {
    width: 100%;
  }
`);

const Description = withAnimationContext(styled(Text).attrs({
  forwardedAs: 'p',
  variant: 'bodySmall',
})`
  ${fadeInAnimation(DELAYS.THIRD)};
`);

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding-bottom: ${cssSpacing('looser-3')};

  @media (max-width: ${screenSmMax}) {
    flex-direction: column;
    padding-bottom: ${cssSpacing('tighter-2')};
  }
`;

const LinkBox = styled.div`
  @media (max-width: ${screenSmMax}) {
    padding-top: 0px;
  }
`;

const InnerTextContainer = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  width: 65%;
  align-items: flex-end;

  @media (max-width: ${screenSmMax}) {
    align-items: flex-start;
    text-align: left;
    padding-bottom: ${cssSpacing('base')};
  }
`;

const RelatedTextLink = withAnimationContext(styled(ButtonTertiary)`
  ${fadeInAnimation(DELAYS.FOURTH)};
  color: ${({ theme }) => theme.box.text};

  &:hover:not(:focus):not(:disabled) {
    transform: none;
    color: ${({ theme }) => hexToRgba(theme.box.text, 0.25)};
  }
`);

const TRACK_CAT = 'module-relatedLinksModule';

export type RelatedLinkModuleType = {
  relatedLink: RelatedLinkType;
};

export const RelatedLink = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  RelatedLinkModuleType
>(({ relatedLink = {} }, ref) => {
  return (
    <RelatedLinkBox ref={ref as React.RefObject<HTMLDivElement>}>
      <TextContainer>
        <Headline>{relatedLink.headline}</Headline>
        <InnerTextContainer>
          <Subtext
            dangerouslySetInnerHTML={{
              __html: formatText(relatedLink.subtext),
            }}
          />
          <Description>{relatedLink.description}</Description>
        </InnerTextContainer>
      </TextContainer>
      <LinkBox>
        <InteractiveElement
          customComponent={RelatedTextLink}
          href={fixHref(relatedLink.link)}
          trackingCategory={TRACK_CAT}
          trackingLabel={`Link - ${relatedLink.linkText}`}
          iconTrailing={() => <IconArrowRight iconSize={16} />}
          condensedAll
          buttonSize="sm"
        >
          {relatedLink.linkText}
        </InteractiveElement>
      </LinkBox>
    </RelatedLinkBox>
  );
});
