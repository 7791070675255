// ignolre-string-externalization

import React from 'react';
import { ModuleType } from '../types';

import { ContentGrid } from '../../ContentGrid';
import { makeColorSetClass } from '@spotify-internal/encore-web';

type EditorialGridModuleType = ModuleType & {
  headline?: string;
  subtext?: string;
  items?: any[];
};

const TRACK_CATEGORY = 'module-editorialGrid';
export const EditorialGridModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  EditorialGridModuleType
>(({ id, headline, subtext, items = [], themeName }, ref) => {
  return (
    <div
      className={makeColorSetClass(
        themeName === 'blackOnWhite' ? 'base' : 'invertedDark',
      )}
    >
      <ContentGrid
        ref={ref}
        trackCategory={TRACK_CATEGORY}
        data-testid={`editorial-grid-module-${id}`}
        headline={headline}
        subtext={subtext}
        items={items}
        featuredItems={2}
      />
    </div>
  );
});
