import React, { ReactNode, useEffect, useRef } from 'react';
import { useTracking } from '../../features/Tracking';
import { useCurrentUser } from '../CurrentUserProvider';
import { useVisibility } from './useVisibility';

interface TrackVisibilityProps {
  threshold?: number;
  children: ReactNode;
  eventCategory: string;
  eventLabel: string;
}

export const TrackVisibility: React.FC<TrackVisibilityProps> = ({
  threshold,
  eventCategory,
  eventLabel,
  children,
}) => {
  const [isVisible, ref] = useVisibility(threshold);
  const shown = useRef(false);
  const { sendEvent } = useTracking();
  const user = useCurrentUser();

  useEffect(() => {
    if (isVisible && !shown.current && user !== undefined) {
      shown.current = true;
      sendEvent({
        eventCategory,
        eventAction: 'view',
        eventLabel,
      });
    }
  }, [eventCategory, eventLabel, isVisible, sendEvent, user]);
  return <div ref={ref}>{children}</div>;
};
