import { IconPlay } from '@spotify-internal/encore-web';
import {
  ContentfulAsset,
  ContentfulImage,
} from '@spotify-internal/frodor-modules';
import React from 'react';
import { useT } from '../../../features/src/i18n';
import { InteractiveElement } from '../../utils/InteractiveElement';
import { DEFAULT_SOCIAL_CARD } from '../Layout/PageHead';
import {
  StyledLink,
  ImageWrapper,
  ItemLabel,
  ItemTitle,
  ItemText,
} from './index.styles';

type ContentItem = {
  url: string;
  contentType: string;
  title: string;
  image?: ContentfulAsset;
  label?: string;
  description?: string;
  linkExternal?: boolean;
};

export type ContentGridItemProps = {
  item: ContentItem;
  trackCategory: string;
  placeholders: boolean;
  featured: boolean;
  className?: string;
};

const SyledLinkWithComponent = ({
  component,
  children,
  ...rest
}: {
  component?: React.ElementType;
  children?: React.ReactNode;
  [key: string]: any;
}) => {
  return (
    <StyledLink as={component} {...rest}>
      {children}
    </StyledLink>
  );
};

/**
 * A fancy way to render a few props.
 *
 * Possible refactor opportunities:
 * - Renaming - this component is not coupled to any grid
 * - Remove "featured" prop dependency, and use transform
 * - Rename placeholders prop, its confusing
 * - Use React.Context to remove prop drilling of trackCategory
 */
export const ContentGridItem: React.FC<ContentGridItemProps> = ({
  item,
  trackCategory,
  placeholders = true,
  className,
  featured = false,
}) => {
  const {
    title,
    url,
    image,
    contentType,
    description,
    label,
    linkExternal = false,
  } = item;
  const t = useT();
  return (
    <article className={className}>
      <InteractiveElement
        customComponent={SyledLinkWithComponent}
        href={url}
        target={linkExternal ? '_blank' : '_self'}
        aria-label={`${t(
          'FRODOR_a55c16',
          'Go to {title}',
          'Go to means to click a link to follow it',
          {
            title,
          },
        )}`}
        trackingLabel={trackCategory}
        trackingCategory={`Content Link - [${contentType}]: ${title}`}
      >
        <ImageWrapper
          video={contentType === 'video' || contentType === 'videoPage'}
          featured={featured}
        >
          {(image && (
            <ContentfulImage asset={image} placeholder={placeholders}>
              <IconPlay />
            </ContentfulImage>
          )) || (
            // placeholder if no image, for now
            <img
              data-src={DEFAULT_SOCIAL_CARD}
              alt={`${t(
                'FRODOR_bfbc75',
                'Spotify for Artists',
                '',
              )} - ${contentType}`}
              className="lazyload"
            />
          )}
        </ImageWrapper>
        {label && <ItemLabel>{label}</ItemLabel>}
        {title && <ItemTitle>{title}</ItemTitle>}
      </InteractiveElement>
      {description && <ItemText>{description}</ItemText>}
    </article>
  );
};
