// ignore-string-externalization

import React from 'react';
import styled from 'styled-components';
import {
  IconArrowRight,
  screenMdMin,
  cssSpacing,
  Text,
  ButtonTertiary,
} from '@spotify-internal/encore-web';
import {
  DELAYS,
  fadeInAnimation,
  withAnimationContext,
} from '@spotify-internal/frodor-modules';

import { hexToRgba } from '../../../../features/src/color-conversion';
import { ContentfulImage } from '../../Contentful/ContentfulImage';
import { ContentfulAsset } from '../../Contentful';
import { ImageAspectBox1x1 } from '../../ui';
import { fixHref } from '../../utils';
import { InteractiveElement } from '../../../utils/InteractiveElement';

type ThreeUpSegmentType = {
  headline?: string;
  body?: string;
  linkText?: string;
  linkUrl?: string;
  image?: ContentfulAsset;
};

const Segment = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const ImageBox = styled(ImageAspectBox1x1)`
  width: 100%;
  padding: 0px ${cssSpacing('base')};

  @media (max-width: ${screenMdMin}) {
    width: 60%;
    align-self: center;
  }
`;

const Headline = withAnimationContext(styled(Text).attrs({
  variant: 'titleMedium',
  paddingBottom: '0.75em',
})`
  ${fadeInAnimation(DELAYS.THIRD)};
  padding-top: ${cssSpacing('base')};
  padding-bottom: ${cssSpacing('base')};

  @media (max-width: ${screenMdMin}) {
    padding-top: ${cssSpacing('base')};
  }
`);

const SegmentTextLink = styled(ButtonTertiary)`
  text-align: center;

  color: ${({ theme }) => theme.box.text};
  &:hover:not(:focus):not(:disabled) {
    transform: none;
    color: ${({ theme }) => hexToRgba(theme.box.text, 0.5)};
  }
`;

const BodySection = withAnimationContext(styled(Text).attrs({
  variant: 'bodyMedium',
  forwardedAs: 'p',
})`
  ${fadeInAnimation(DELAYS.FOURTH)};
`);

type ThreeUpSegmentParams = {
  threeUpSegment: ThreeUpSegmentType;
};

const TRACK_CAT = 'module-featuredContent3Up';

export const ThreeUpSegment: React.FC<ThreeUpSegmentParams> = ({
  threeUpSegment = {},
}) => {
  return (
    <Segment>
      {threeUpSegment.image && (
        <ImageBox>
          <ContentfulImage asset={threeUpSegment.image} />
        </ImageBox>
      )}
      <Headline>{threeUpSegment.headline}</Headline>
      <BodySection>{threeUpSegment.body}</BodySection>
      {threeUpSegment.linkText && threeUpSegment.linkUrl && (
        <InteractiveElement
          customComponent={SegmentTextLink}
          href={fixHref(threeUpSegment.linkUrl)}
          trackingCategory={TRACK_CAT}
          trackingLabel={`Link - ${threeUpSegment.linkText}`}
          iconTrailing={() => <IconArrowRight iconSize={16} />}
          condensed
          buttonSize="sm"
        >
          {threeUpSegment.linkText}
        </InteractiveElement>
      )}
    </Segment>
  );
};
