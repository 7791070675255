import React from 'react';

import { LinkGridModule as FrodorLinkGridModule } from '@spotify-internal/frodor-modules';
import { ModuleType } from '../types';
import { toContentItem } from './toContentItem';
import { GridItem } from './LinkGridFields';

/**
 * We tried to use cf-contentful-types to generate the types for the LinkGridFields
 * But, since the src/features/Contentful/index messes with the shape, its difficult or impossible to get the fields to map up
 * This is a huge disadvantage for a component like this that complex nested types :(
 */
type LinkGridFields = {
  heading: string;
  subhead: string;
  griditems: {
    items: GridItem[];
  };
};

export const LinkGridModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  LinkGridFields & ModuleType
>(({ heading, subhead, griditems, colors }, ref) => {
  return (
    <FrodorLinkGridModule
      colors={colors}
      headline={heading}
      subtext={subhead}
      items={griditems.items.map(toContentItem)}
      ref={ref}
    />
  );
});
